/* From Uiverse.io by joe-watson-sbf */
.flip-card {
  background-color: transparent;
  perspective: 1000px;
  font-family: sans-serif;
}

.title {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center align the content */
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid rgb(67, 67, 67); /* Dark Blue Border */
  border-radius: 1rem;
}

.flip-card-front {
  color: #003366; /* Dark Blue Text */
  overflow: hidden; /* Ensure content doesn't overflow */
  transition: opacity 0.3s ease; /* Add transition for smoother effect */
}

.flip-card-back {
  background: linear-gradient(120deg, #5a526f 30%, #49435c 88%, #433d56 40%, #352F44 78%);
  color: #ffffff; /* White Text */
  transform: rotateY(180deg);
}

/* Image styling for the front */
.flip-card-front img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Position the image behind the text */
}

/* Styling for the overlay */
.flip-card-front .flip-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  color: #fff;
  padding: 20px;
  text-align: center;
  z-index: 1; /* Ensure the overlay is above the image */
}

/* Hiding front content when flipped */
.flip-card:hover .flip-card-front {
  opacity: 0; /* Hide front content when flipped */
}
